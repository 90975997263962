@import "../lib/colors";
@import "../lib/sizes";
@import "../lib/icons";

/* Panel container that holds our cards. */
.hui-panel {
	display: inline-block;
	vertical-align: top;
	position: relative;
	min-width: 200px; // usually overwritten by JS `width`
	flex: 1 0 0;
	max-width: 100%; // Our panel should never exceed the size of it's container (dashboard)

	.hui-panel-margin-container {
		margin: 0 4px; // usually overwritten by JS `gutter`
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		height: 100%;
	}

	&.hui-panel-sm .hui-card {
		height: $card-small-height;
	}

	&.hui-panel-md .hui-card {
		height: $card-medium-height;
	}

	&.hui-panel-infinite .hui-card {
		height: auto;
		min-height: $card-small-height;
	}
}

.hui-card-title-wrapper {
	flex-shrink: 0;
	display: flex;
	height: $panel-title-height;
	line-height: $panel-title-height;

	.hui-card-title {
		color: $text-light;
		font-size: 18px;
		padding-right: 8px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;

		&.hui-card-title-dropdown {
			overflow: visible;
			max-width: 100%;
			min-width: 0; // http://stackoverflow.com/a/12131365/231730
		}

		.dropdown-toggle {
			height: $panel-title-height;
		}
	}

	.hui-card-titlenote {
		font-size: 12px;
		color: $dark-gray-5s;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		flex-grow: 1;
		text-align: right;

		.hui-system-status {
			text-transform: uppercase;

			&.hui-system-status-online {
				color: $status-green;
			}

			&.hui-system-status-offline {
				color: $status-red;
			}
		}
	}

	.hui-card-title-info-icon-wrapper {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex: 1;

		.withpopover-hover-wrapper {
			display: flex;
		}

		.hui-info-icon {
			opacity: .54;
			@include hui-card-title-icon-info;
		}
	}
}

.hui-panel-stack-wrapper {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	flex-grow: 1;
}

.hui-stacked-panel {
	display: flex;
	flex-direction: column;

	.hui-card-title {
		overflow: initial;
	}
}

.hui-stacked-panel-card-wrapper {
	position: relative;
	display: flex;
	flex: 3;
	flex-direction: column;
	overflow: auto;
}

/* Animation-related classes. */
.hui-panel-animation-container {
	position: relative;
}

.hui-panel-card-transition-enter {
	.hui-card-title-wrapper {
		opacity: 0;
	}

	&.hui-panel-card-transition-enter-active {
		.hui-card-title-wrapper {
			transition: opacity 300ms cubic-bezier(0.645, 0.045, 0.355, 1.000); // easeInOutCubic
			opacity: 1;
		}
	}
}

.hui-panel-card-transition-leave {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	.hui-card-title-wrapper {
		opacity: 1;
	}

	.hui-card {
		opacity: 1;
	}

	&.hui-panel-card-transition-leave-active {
		.hui-card-title-wrapper {
			transition: opacity 300ms cubic-bezier(0.645, 0.045, 0.355, 1.000); // easeInOutCubic
			opacity: 0;
		}

		.hui-card {
			transition: opacity 300ms cubic-bezier(0.645, 0.045, 0.355, 1.000); // easeInOutCubic
			opacity: 0;
		}
	}
}
