@import "../lib/colors.scss";

.hui-label {
	padding: 2px 5px;
	border-radius: 3px;
	color: $text-inverse;
	text-shadow: 1px 1px 0 $dark-gray-5s;
	font-weight: 300;

	&.hui-label-warning {
		background-color: $label-warning;
	}
	&.hui-label-danger {
		background-color: $label-danger;
	}
}
