@mixin hui-icon($file, $width, $height: $width) {
	background: url('../images/' + $file) center center no-repeat;
	@include bg-image-container($width, $height);
}

@mixin bg-image-container($width, $height: $width) {
	display: inline-block;
	background-size: $width $height;
	width: $width;
	height: $height;
	min-width: $width;
	min-height: $height;
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
}

@mixin hui-icon-loading($size:24px) {
	@include hui-icon('ajax-loader.gif', $size);
}

@mixin hui-icon-header-loading($size:24px) {
	@include hui-icon('ajax-loader-header.gif', $size);
}

@mixin hui-icon-status-good-small {
	@include hui-icon('icn-success.svg', 17px);
}

@mixin hui-icon-status-good-small-selected {
	@include hui-icon('icn-success-selected.svg', 17px);
}

@mixin hui-icon-status-good-medium {
	@include hui-icon('icn-success.svg', 20px);
}

@mixin hui-icon-status-good-medium-selected {
	@include hui-icon('icn-success-selected.svg', 20px);
}

@mixin hui-icon-status-good-large {
	@include hui-icon('icn-success.svg', 25px);
}

@mixin hui-icon-status-good-xlarge {
	@include hui-icon('icn-success.svg', 36px);
}

@mixin hui-icon-status-inactive-small {
	@include hui-icon('icn-inactive.svg', 17px);
}

@mixin hui-icon-status-inactive-small-selected {
	@include hui-icon('icn-inactive-selected.svg', 17px);
}

@mixin hui-icon-status-inactive-medium {
	@include hui-icon('icn-inactive.svg', 20px);
}

@mixin hui-icon-status-inactive-medium-selected {
	@include hui-icon('icn-inactive-selected.svg', 20px);
}

@mixin hui-icon-status-inactive-large {
	@include hui-icon('icn-inactive.svg', 25px);
}

@mixin hui-icon-status-inactive-xlarge {
	@include hui-icon('icn-inactive.svg', 36px);
}

@mixin hui-icon-status-warn-small {
	@include hui-icon('icn-warn.svg', 17px);
}

@mixin hui-icon-status-warn-small-selected {
	@include hui-icon('icn-warn-selected.svg', 17px);
}

@mixin hui-icon-status-warn-medium {
	@include hui-icon('icn-warn.svg', 20px);
}

@mixin hui-icon-status-warn-medium-selected {
	@include hui-icon('icn-warn-selected.svg', 20px);
}

@mixin hui-icon-status-warn-large {
	@include hui-icon('icn-warn.svg', 25px);
}

@mixin hui-icon-status-warn-xlarge {
	@include hui-icon('icn-warn.svg', 36px);
}

@mixin hui-icon-status-bad-small {
	@include hui-icon('icn-critical.svg', 17px);
}

@mixin hui-icon-status-bad-small-selected {
	@include hui-icon('icn-critical-selected.svg', 17px);
}

@mixin hui-icon-status-bad-medium {
	@include hui-icon('icn-critical.svg', 20px);
}

@mixin hui-icon-status-bad-medium-selected {
	@include hui-icon('icn-critical-selected.svg', 20px);
}

@mixin hui-icon-status-bad-large {
	@include hui-icon('icn-critical.svg', 25px);
}

@mixin hui-icon-status-bad-xlarge {
	@include hui-icon('icn-critical.svg', 36px);
}

@mixin hui-icon-status-running-medium {
	@include hui-icon('icn-running-16.png', 16px, 12px);
}

@mixin hui-icon-status-running-medium-transparent {
	@include hui-icon('icn-running-16-transparent.png', 16px, 12px);
}

@mixin hui-icon-status-queued-medium {
	@include hui-icon('icn-clock-16.png', 16px);
}

@mixin hui-icon-chevron-right {
	@include hui-icon('icn-chevron-right.png', 6px, 11px);
}

@mixin hui-icon-chevron-right-selected {
	@include hui-icon('icn-chevron-right-selected.png', 6px, 11px);
}

@mixin hui-icon-chevron-right-disabled {
	@include hui-icon('icn-chevron-right-disabled.png', 6px, 11px);
}

@mixin hui-icon-close {
	@include hui-icon('icn-close.png', 12px, 12px);
}
@mixin hui-icon-close-hover {
	@include hui-icon('icn-close.png', 12px, 12px);
}

@mixin hui-icon-home {
	@include hui-icon('icn-home.png', 18px, 15px);
}

@mixin hui-icon-search {
	@include hui-icon('icn-search.png', 14px);
}

@mixin hui-icon-sort-up {
	@include hui-icon('icn-sort-AZ.png', 12px);
}

@mixin hui-icon-sort-down {
	@include hui-icon('icn-sort-ZA.png', 12px);
}

@mixin hui-icon-toggle {
	@include hui-icon('icn-arrow-down.png', 12px);
}

@mixin hui-icon-toggle-selected {
	@include hui-icon('icn-arrow-down-selected.png', 12px);
}

@mixin hui-icon-sidebar-close {
	@include hui-icon('icn-menu-close.png', 16px);
}

@mixin hui-icon-sidebar-open {
	@include hui-icon('icn-menu-close.png', 16px);
	transform: rotateY(180deg);
}

@mixin hui-icon-user {
	@include hui-icon('icn-user.png', 24px);
}

@mixin hui-icon-product-default {
	display: none;
}

@mixin hui-icon-current-view-community {
	@include hui-icon('icn-community_34x34-@2x.png', 34px);
}
@mixin hui-icon-current-view-support {
	@include hui-icon('icn-support_34x34-@2x.png', 34px);
}
@mixin hui-card-title-icon-info {
	@include hui-icon('icn-info.svg', 20px);
}

.hui-icon-status-good {
	@include hui-icon-status-good-medium;
}
.hui-icon-status-unknown,
.hui-icon-status-warn {
	@include hui-icon-status-warn-medium;
}
.hui-icon-status-bad {
	@include hui-icon-status-bad-medium;
}
.hui-icon-status-running {
	@include hui-icon-status-running-medium;
}
.hui-icon-status-running-transparent {
	@include hui-icon-status-running-medium-transparent;
}
.hui-icon-status-queued {
	@include hui-icon-status-queued-medium;
}
.hui-icon-status-inactive {
	@include hui-icon-status-inactive-medium;
}

a {
	.hui-icon-status-good:hover {
		@include hui-icon-status-good-medium-selected;
	}
	.hui-icon-status-warn:hover {
		@include hui-icon-status-warn-medium-selected;
	}
	.hui-icon-status-bad:hover {
		@include hui-icon-status-bad-medium-selected;
	}
	.hui-icon-status-inactive:hover {
		@include hui-icon-status-inactive-medium-selected;
	}
}
