@import "../lib/colors";
@import "../lib/icons";
@import "../lib/buttons.scss";

.hui-error {
	background: $error-bg-color;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 9px 12px;

	color: $text-light;
	font-size: 13px;
	display: flex;
	text-align: center;
	flex-direction: column;
	justify-content: center;

	.hui-error-icon {
		@include hui-icon-status-warn-large;
		margin-right: 10px;
	}

	button {
		@include hui-button;
		margin: 15px;
	}
}

.hui-panel-row-infinite, .hui-panel-row-sm {
	.hui-error {
		flex-direction: row;
		font-size: 16px;
		align-items: center;
		justify-content: space-between;

		.hui-error-result {
			display: flex;
			align-items: center;
		}

		.hui-error-icon {
			@include hui-icon-status-warn-xlarge;
		}

		button {
			margin: 0 0 0 10px;
		}
	}
}

.hui-scrolling-context {
	.hui-error {
		z-index: -1;
	}
}
