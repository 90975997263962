@import "../lib/colors.scss";
@import "../lib/href.scss";
@import "../lib/baseComponent.scss";
@import "../lib/sizes.scss";

.hui-footer {
	@include baseComponent;
	background-color: $chrome-bg-color;
	border-top: 1px solid $border-color-default;
	color: $text-light;
	padding: 6px 24px;
	height: $footer-height;


	@media (max-width: $mobile-breakpoint) {
		height: auto; // On mobile, text wraps and this fixed height breaks layout.
	}
}
