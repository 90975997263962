$sidebar-width: 300px;
$footer-height: 27px;
$header-height: 55px;
$navbar-height: 43px;
$subnavbar-height: $navbar-height - 13px;
$card-medium-height: 300px;
$card-small-height: 50px;

$row-height: 44px;

$panel-title-height: 30px;

$product-row-height: ($row-height - 10px);
$treenode-height: ($row-height - 15px);
$product-category-row-height: ($product-row-height * .75);
$product-count-height: ($product-row-height - 14px);
$treenode-count-height: ($treenode-height - 11px);
$subnav-row-height: ($row-height - 13px);

$base-z-index: 10;

// Responsive sizing
$mobile-breakpoint: $sidebar-width * 2;

$sidebar-width-mobile: 100%;

$banner-height: 35px;
