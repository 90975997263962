@import 'node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables';
@import 'node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins/reset-text';
@import 'node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins/vendor-prefixes';

@import 'node_modules/bootstrap-sass/assets/stylesheets/bootstrap/popovers';

@import "../lib/colors.scss";

.hui-tooltip {

	.popover-content {
		padding: 20px 30px 20px 20px;
	}

	.hui-tooltip-close-button {
		position: absolute;
		top: 10px;
		right: 10px;
		font-size: 20px;
		cursor: pointer;
		color: $text-disabled;
		height: 20px;
		display: inline-block;
		padding: 10px 10px 0 5px;
		line-height: 7px;

		&:after {
			content: "×"; // unicode "times"
		}

		&:hover {
			color: $text-med;
		}
	}

	.hui-tooltip-content {

		a, a:hover, a:active, a:visited {
			font-size: 12px;
			color: $link-blue;
			text-decoration: none;
			cursor: pointer;
		}

		h1 {
			font-size: 15px;
			font-weight: bold;
			margin: 20px 0;
			color: $text-dark;

			&:first-child {
				margin-top: 0;
			}
		}

		h2 {
			font-size: 13px;
			font-weight: bold;
			color: $text-dark;
			margin-bottom: 0;

			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		p {
			font-size: 12px;
			font-weight: normal;

			&:last-child {
				margin-bottom: 0;
			}
		}

		h2 + p {
			margin-top: 0;
		}

		hr {
			// something
		}

		aside {
			font-size: 10px;
			font-weight: normal;
			color: $text-disabled;
		}
	}
}
