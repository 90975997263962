.hui-loading-context {
	// position: relative;
}

.hui-loading-view {
	background: url(../images/ajax-loader.gif) center center no-repeat;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	// Kellum Image Replacement
	text-indent: -100%;
	white-space: nowrap;
	overflow: hidden;
}
