@import '../components/dropdownButton.scss';

@mixin dropdown {

	.btn-group {
		display: block;
	}

	:focus,
	:active:focus {
		outline: none;
	}

	.dropdown-toggle {
		color: inherit;
		font-size: inherit;
		font-family: inherit;
		padding: 1px 0 4px;
		line-height: normal;
		text-decoration: none;

		max-width: 100%;
		float: none;
		text-align: left;

		:hover,
		:active {
			text-decoration: underline;
		}

		> span {
			display: inline-block;
			vertical-align: middle;
			margin-right: 5px;

			&:first-child {
				max-width: 95%;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}

	.dropdown-menu {
		max-height: 80vh;
		overflow-y: auto;

		@include dropdown-menu;
	}

	.dropdown-menu.dropdown-menu-right {
		left: auto;
	}
}

@mixin dropdown-menu {
	max-width: 100%;

	& > li > a {
		text-decoration: none;
		text-overflow: ellipsis;
		overflow: hidden;
		outline: none;
	}

	& > li.hui-menuitem-checked > a:before {
		content: "✓";
		position: absolute;
		margin-left: -18px;
		display: inline-block;
		width: 20px;
		text-align: center;
	}
}
