@import '../lib/colors';
@import '../lib/baseComponent';
@import './node_modules/@barracuda/react-with-popover/src/styles/partials/arrows';

$background-color: white;
$border-color: $border-color-default;
$arrow-size: 10px;
$border-width: 1px;

// bootstrap-style (not react-with-popover)
.hui-popover {
	.popover-content {
		padding: 9px;
	}
}

// This is a popover appearing below a target
.hui-withpopover-tether-element-attached-top.hui-withpopover-tether-element-attached-center {
	.hui-withpopover-wrapper.hui-withpopover-auto-arrow {
		margin-top: $arrow-size + 6px;
		@include addArrow("top", $background-color, $border-color, $arrow-size, $border-width);
	}
}

// This is a popover appearing above a target
.hui-withpopover-tether-element-attached-bottom.hui-withpopover-tether-element-attached-center {
	.hui-withpopover-wrapper.hui-withpopover-auto-arrow {
		margin-bottom: $arrow-size + 6px;
		@include addArrow("bottom", $background-color, $border-color, $arrow-size, $border-width);
	}
}

// This is a popover appearing to the left of a target
.hui-withpopover-tether-element-attached-right.hui-withpopover-tether-element-attached-middle {
	.hui-withpopover-wrapper.hui-withpopover-auto-arrow {
		margin-left: $arrow-size + 6px;
		@include addArrow("left", $background-color, $border-color, $arrow-size, $border-width);
	}
}

// This is a popover appearing to the right of a target
.hui-withpopover-tether-element-attached-left.hui-withpopover-tether-element-attached-middle {
	.hui-withpopover-wrapper.hui-withpopover-auto-arrow {
		margin-right: $arrow-size + 6px;
		@include addArrow("right", $background-color, $border-color, $arrow-size, $border-width);
	}
}

.hui-withpopover-tether-element {
	z-index: 11;
}

.hui-withpopover-wrapper {
	max-width: 320px;
	position: relative;
	@include baseComponent();
	box-shadow: 10px 10px 12px -2px rgba(0, 0, 0, 0.25);
	padding: 15px 20px;
	border: $border-width solid $border-color;
	z-index: 5;
	background-color: $background-color;
	line-height: 20px;

	.hui-withpopover-content-title, .hui-withpopover-content-subtitle {
		margin: 0;
		padding: 0;
		font-size: 14px;
		font-weight: normal;

	}

	.hui-withpopover-content-title {
		color: $dark-gray-7s;
	}

	.hui-withpopover-content-subtitle {
		font-weight: bold;
		margin-bottom: 12px;

	}

	.withpopover-popover-content-wrapper {
		h1 {
			letter-spacing: normal;
		}
	}

	.hui-withpopover-list {
		margin: 0;
		padding: 0 15px;
		max-width: 260px; // minus sides
		white-space: normal;
		word-wrap: break-word;

		.hui-withpopover-list-item {
			margin-top: 5px;
		}
	}
}
