@import '../lib/colors';
@import '../lib/sizes';

@mixin pane {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: $base-z-index + 2;
	position: fixed;
}

@mixin cubic-transition($property...) {
	transition-delay: 0;
	transition-property: $property, transform;
	transition-duration: 500ms;
	transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); // easeInOutCubic;
}

.hui-wrapper {
	.hui-header {
		@include pane;
		// height: 55px; // set in header.scss
		z-index: $base-z-index + 4;
		bottom: auto;
	}
	.hui-sidebar {
		@include pane;
		@include cubic-transition(left, width);
		width: $sidebar-width; // NOT set in sidebar.scss
		top: $header-height;
		right: auto;
		background: $chrome-bg-color;
		z-index: $base-z-index + 3;

		@media (max-width: $mobile-breakpoint) {
			width: $sidebar-width-mobile;
		}
	}

	.hui-navbar {
		@include pane;
		@include cubic-transition(left, width);
		position: fixed;
		top: $header-height;
		left: $sidebar-width;
		bottom: auto;

		@media (max-width: $mobile-breakpoint) {
			left: 0;
		}
	}

	.hui-footer {
		@include pane;
		@include cubic-transition(left, width);
		position: fixed;
		// height: $footer-height; // set in footer.scss
		left: $sidebar-width;
		right: auto;
		width: 100%;
		top: auto;

		@media (max-width: $mobile-breakpoint) {
			left: 0;
		}
	}

	// HACKS FOR CONFERENCE BANNER
	.hui-banner {
		@include pane;

		top: $header-height;
		height: $banner-height;
	}
	.hui-sidebar {
		top: $header-height + $banner-height;
	}
	.hui-navbar {
		top: $header-height + $banner-height;
	}
}

@media (min-width: $mobile-breakpoint) {
	.hui-wrapper.hui-state-sidebar-hidden {
		.hui-sidebar {
			position: fixed;
			left: -$sidebar-width;
		}
		.hui-navbar {
			left: 0;
		}
		.hui-footer {
			left: 0;
		}
	}
}

@media (max-width: $mobile-breakpoint) {
	.hui-wrapper.hui-state-sidebar-hidden-mobile {
		.hui-sidebar {
			position: fixed;
			left: -$sidebar-width-mobile;
		}
		.hui-navbar {
			left: 0;
		}
		.hui-footer {
			left: 0;
		}
	}
}

.hui-wrapper.hui-state-banner-hidden {
	.hui-navbar {
		top: $header-height;
	}
	.hui-sidebar {
		top: $header-height;
	}
	.hui-contentarea-banner-symbiote {
		padding-top: $header-height;
	}
}

// separate class for each component that may want to interact with the contentArea
.hui-contentarea-header-symbiote {
	padding-top: $header-height;
}

.hui-contentarea-banner-symbiote {
	padding-top: $banner-height;
}

.hui-contentarea-sidebar-symbiote {
	padding-left: $sidebar-width;
	@include cubic-transition(padding-left);

	&.hui-contentarea-sidebar-initialposition {
		transition: none;
	}

	@media (max-width: $mobile-breakpoint) {
		padding-left: 0;
	}
}

.hui-contentarea-footer-symbiote {
	padding-bottom: $footer-height;
}

@media (min-width: $mobile-breakpoint) {
	.hui-contentarea-sidebar-symbiote.hui-state-sidebar-hidden {
		padding-left: 0;
	}
}

.hui-contentarea-navbar-symbiote {
	padding-top: $navbar-height;

	&.hui-contentarea-header-symbiote {
		padding-top: $header-height + $navbar-height;
	}
}

.hui-contentarea-banner-symbiote {
	padding-top: $banner-height;

	&.hui-contentarea-navbar-symbiote {
		padding-top: $navbar-height + $banner-height;

		&.hui-contentarea-header-symbiote {
			padding-top: $header-height + $navbar-height + $banner-height;
		}
	}
}

.hui-wrapper.hui-state-banner-hidden {
	&.hui-contentarea-banner-symbiote {
		display: none;

		&.hui-contentarea-navbar-symbiote {
			padding-top: $navbar-height;

			&.hui-contentarea-header-symbiote {
				padding-top: $header-height + $navbar-height;
			}
		}
	}
}
