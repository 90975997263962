@import "../lib/colors.scss";

.hui-href {
	color: $text-link;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}

	&:visited {
		color: $text-link;
	}
}

.hui-link-divider {
	padding: 0 .5em;
}
