@import '../lib/colors';
@import '../lib/sizes';
@import '../lib/baseComponent';

.conferenceBanner {
	@include baseComponent;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 5px 10px;
	font: 14px/22px Roboto, Arial, sans-serif;
	background: #b5e7ff;
	color: #184a63;
	border-bottom: 1px solid #97d4f2;

	> div {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		flex: 1 0 24px;
		padding-left: 24px;
	}

	.conferenceBannerIcon {
		width: 24px !important;
		height: 24px !important;
		color: #184a63;
	}

	.conferenceBannerText {
		padding: 0 10px;
		justify-content: center;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		height: 22px;
	}

	.conferenceBannerLink {
		padding: 0 10px 0 0;
		white-space: nowrap;
		text-decoration: none;
		font-weight: 700;
		color: #184a63;
		text-transform: uppercase;

		&:hover {
			text-decoration: underline;
		}
	}

	.conferenceBannerCloseIcon {
		flex: 0 1 24px;
		width: 24px !important;
		height: 24px !important;
		color: #184a63;
	}
}
