@import "../lib/colors";
@import "../lib/href";
@import "../lib/baseComponent";
@import "../lib/dropdown";
@import "../lib/icons";
@import "../lib/sizes";
@import '../lib/logos';

$header-padding: 10px;
$header-padding-bottom: $header-padding - 1;
$header-content-height: $header-height - $header-padding - $header-padding-bottom;

.hui-header {
	@include baseComponent;
	background-color: $header-bg-color;
	padding: $header-padding $header-padding $header-padding-bottom;
	font-size: 14px;
	height: $header-height;
	display: flex;
	justify-content: space-between;

	$link-padding: 18px;
	.hui-href {
		color: $text-inverse;
		display: inline-block;
		line-height: $header-content-height;
		padding: 0 $link-padding;

		&:visited {
			color: $text-inverse; // override default visited
		}
	}

	.hui-logo-product {
		white-space: nowrap;
	}

	$loadingSpinnerSize: 18px;

	// this wrapper class fixes specificity issues created by its namespacing in dropdownButton.scss
	&.hui-bootstrap-container {

		.hui-account,
		.hui-user {
			@include dropdown;
			line-height: $header-content-height;

			.hui-user-icon {
				@include hui-icon-user;
			}

			.hui-loading-spinner {
				@include hui-icon-loading($loadingSpinnerSize);
			}

			.dropdown-menu {
				font-size: 12px;
			}
		}

		.hui-account {

			@mixin dropdownToggle($dropdownWidth, $dropdownPadding) {
				width: $dropdownWidth;
				padding-left: $dropdownPadding;
				padding-right: $dropdownPadding;
				text-overflow: ellipsis;
				overflow: hidden;

				$dropdownTitleInnerSpacing: 5px; // from bootstrap, the space between each title span
				$dropdownCaretWidth: 10px;
				$dropdownTitleWidth: $dropdownWidth - (2* $dropdownPadding)
					- $dropdownTitleInnerSpacing - $dropdownCaretWidth;

				> .hui-loading-spinner:first-child {
					width: auto;
				}

				:hover {
					text-decoration: none;
				}
			}

			.dropdown-toggle {
				margin-top: -2px;
				@include dropdownToggle(250px, 5px);
			}

			@media (max-width: $mobile-breakpoint) {
				.dropdown-toggle {
					@include dropdownToggle(150px, 5px);
				}
			}
		}

		.hui-user {
			color: $text-inverse;

			// @include dropdown overrides
			.dropdown-toggle {
				padding: 0 0 5px $link-padding;
				vertical-align: middle;
				box-shadow: none;
				margin-top: 0; // It's already 0, but Safari is converting 0em -> 2px

				> span {
					margin-right: 8px;
				}
			}

			@media (max-width: $mobile-breakpoint) {
				// overrides dropdown mixin
				.dropdown-toggle > .hui-user-title {
					display: none;
				}
			}
		}

		.hui-account,
		.hui-links,
		.hui-user {
			display: inline-block;
		}

		.hui-loading-context {
			.hui-loading-view {
				left: auto;
				width: 100px;
				margin: 18px;
				height: 16px;
				width: 16px;
				background: url(../images/ajax-loader-header.gif) center center no-repeat;
				background-size: 16px 16px;
			}
		}
	}
}

// use logos.scss to substitute a header color and logo by product id
.hui-header {
	@each $name, $color, $image in $logos {
		&.hui-header-product-#{$name} {
			background: $color;
			.hui-logo-image {
				background-image: url(../images/logos/#{$image});
			}
		}
	}
}

.hui-logo {
	line-height: $header-content-height;
	color: $text-inverse;
	display: flex;
}

.hui-logo-image {
	margin-right: 6px;
	flex: 1;
	background: url(../images/logos/primary/CloudControl_rev_0.svg) left center no-repeat;
	background-size: cover;
	width: 40px;
	height: $header-content-height;
}

.hui-logo-name {
	flex: 1;
}

.hui-logo-company {
	line-height: ($header-content-height)/3;
	font-size: 11px;
	margin-top: 2px;
}
.hui-logo-product {
	line-height: ($header-content-height)/3 * 2;
	font-size: 18px;
	margin-bottom: -2px;
}

.hui-header-product {
	.hui-logo-image {
		background: url(../images/logos/primary/CloudControl_rev_0.svg) left center no-repeat;
		background-size: contain;
		width: 350px;
		height: $header-content-height;
		margin-left: -1px;
		text-indent: -100%;
		overflow: hidden;
		white-space: nowrap;
	}
	.hui-logo-name,
	.hui-logo-company,
	.hui-logo-product {
		display: none;
	}
}

@media (max-width: $mobile-breakpoint) {
	.hui-header .hui-logo {
		width: 44px;

		.hui-logo-image {
			width: 44px;
			background-size: cover;
			padding-left: 0;
		}
		.hui-logo-name {
			display: none;
		}
	}
}

.hui-header-left-contents {
	display: flex;
	align-items: center;
}
