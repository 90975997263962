// lato fonts
@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700);
// roboto
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');

@mixin baseComponent {

	font-family: Lato, helvetica, arial, sans-serif !important;
	font-size: 12px;

	box-sizing: border-box;
	* {
		box-sizing: inherit;
	}

	a {
		color: $link-blue;
		text-decoration: none;
		cursor: pointer;
	}

	input:focus {
		box-shadow: none;
	}
}

@mixin baseComponent2 {
	@include baseComponent;
	font-family: 'Roboto', helvetica, arial, sans-serif !important;
}
