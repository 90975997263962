@import "../lib/baseComponent";
@import "../lib/colors";
@import "../lib/sizes";
@import '../lib/dropdown';
@import '../lib/icons';
@import "../components/popovers";

.hui-dashboard {
	@include baseComponent;
	background-color: $page-bg-color;

	.hui-dashboard-rows {
		padding: 12px; // usually overwritten by JS `padding`
	}

	.hui-panel-row {
		display: flex;
		margin: 12px 0;

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	.hui-panel-row-md {
		height: 350px;
	}

	.hui-panel-row-sm {
		height: 100px;
	}

	.hui-dashboard-controlbar {
		@include baseComponent;
		background: $chrome-bg-color;
		border-bottom: 1px solid $border-color-default;
		padding: 0 12px;
		line-height: $product-row-height;
		display: flex;
		justify-content: space-between;

		a {
			color: $text-dark;
		}

		.active a {
			color: $white;
		}

		label {
			white-space: nowrap;

			> * {
				vertical-align: middle;
			}
		}

		// this wrapper class fixes specificity issues created by its namespacing in dropdownButton.scss
		&.hui-bootstrap-container {

			.dropdown-toggle {
				display: inline-block;
				max-width: none;
				font-family: inherit;
			}
			.dropdown-menu {
				@include dropdown-menu;
				max-width: none;

				.hui-icon-status {
					vertical-align: text-bottom;
					line-height: 14px;
					margin-bottom: 1px;
				}
				.hui-icon-status-good-small {
					@include hui-icon-status-good-small;
				}
				.hui-icon-status-warn-small {
					@include hui-icon-status-warn-small;
				}
				.hui-icon-status-bad-small {
					@include hui-icon-status-bad-small;
				}
				.hui-state-active {
					.hui-icon-status-good-small {
						@include hui-icon-status-good-small-selected;
					}
					.hui-icon-status-warn-small {
						@include hui-icon-status-warn-small-selected;
					}
					.hui-icon-status-bad-small {
						@include hui-icon-status-bad-small-selected;
					}
				}
				.hui-state-active a, .hui-state-active a:hover {
					color: $white;
					background-color: $barracuda-blue;
				}
				.hui-state-has-active-parent a, .hui-state-has-active-parent a:hover {
					background-color: rgba($barracuda-blue, .3);
				}
			}
		}

		.hui-dashboard-device-info {
			color: $text-med;

			.hui-dashboard-device-info-title {
			}

			.hui-dashboard-device-info-serial {
			}

			.hui-dashboard-device-info-ip {
			}

			.hui-dashboard-device-info-item {
				margin-left: 8px;
				padding-left: 8px;

				& + .hui-dashboard-device-info-item {
					border-left: 1px solid $border-color-dark;
				}
			}
		}
	}
}
