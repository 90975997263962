@import "../lib/colors";
@import "../lib/icons.scss";
@import "../lib/sizes.scss";
@import "../lib/buttons.scss";

$table-card-header-cell-padding: 11px;
$table-card-body-cell-padding: 5px;

.hui-style-align-right {
	text-align: right;
}
.hui-style-align-left {
	text-align: left;
}

.hui-text-status-good {
	color: $status-green;
}

.hui-text-status-bad {
	color: $status-red;
}

.hui-text-status-warn {
	color: $status-yellow;
}

.hui-card {
	word-wrap: break-word;
	overflow-wrap: break-word;
	background: white;
	overflow: auto;
	width: 100%;
	position: relative;
	border: 1px solid $border-color-default;
	border-radius: 3px;

	.hui-title {
		font-size: 14px;
		line-height: 30px;
	}

	&.hui-state-busy {
		opacity: 0.25;
		:after {
			content: 'Loading...';
		}
	}

	/* allows tooltips to layer above titles */
	.highcharts-tooltip span {
		z-index: 9999 !important;
		background-color: white;
		padding: 8px;
		border: 1px solid $border-color-default;
	}

	.hui-pill {
		border-top-right-radius: 46px;
		border-top-left-radius: 46px;
		border-bottom-right-radius: 46px;
		border-bottom-left-radius: 46px;
		padding: 2px 10px;
	}

	// for vertical centering of short content
	&:not(.hui-card-tablecard) {
		@extend .hui-card-style-vertically-centered;
	}
}

.hui-card-style-vertically-centered {
	display: flex;
	flex-flow: column;
	justify-content: center;
}

.hui-card-controller {
	height: 100%;
	flex-grow: 1;
	display: flex;
	flex-direction: column;

	.hui-card {
		flex-grow: 1;
	}
}

.highcharts-container {
	/* keeps the graph centered */
	margin: 0 auto;
}

.hui-card-graphcard {
	overflow: hidden;

	&.hui-card-graphcard-type-pie {
		.hui-card-body > div {
			display: flex;
			justify-content: center;

			& > div {
				flex-shrink: 0;
			}
		}
	}

	.hui-card-donut-title {
		display: block;
		text-align: center;
		font-size: 15px;
		color: black;
		text-shadow: none;
		padding: 0;
		text-transform: uppercase;

		.hui-card-donut-title-value {
			font-size: 30px;
		}
		.hui-card-donut-title-unit {
			font-size: 15px;
		}
	}
	.hui-card-donut-title-subtitle {
		text-align: center;
		display: block;
		font-size: 13px;
		color: $text-light;
		text-shadow: none;
		padding: 0;
		text-transform: uppercase;
	}

	.highcharts-legend .highcharts-legend-item:nth-child(even) .hui-striped-row {
		background-color: $off-white;
	}
}

.hui-tablecard-thead-sticky {
	background: white;

	&.hui-tablecard-thead-sticking {
		background: rgba(white, .8);
	}
}

.hui-card-tablecard {
	.hui-table {
		width: 100%;
		font-size: 12px;

		.hui-table-highlight-cell {
			background-color: $table-highlighted-bg-color;
		}

		&.hui-table-hide-header {
			thead {
				display: none;
			}
		}

		&.hui-table-striped {

			tr:nth-child(odd) td {
				background-color: $off-white;

				&.hui-table-highlight-cell {
					background-color: $table-highlighted-bg-color-odd;
				}
			}

			&.hui-table-hide-header {

				tr:nth-child(even) td {
					background-color: $off-white;
				}

				tr:nth-child(odd) td {
					background-color: transparent;
				}
			}
		}

		.reactable-header-sortable {
		    cursor: pointer;
		}

		th {
			text-align: left;
			font-weight: normal;
			color: $text-med;
			border-bottom: 1px solid $border-color-light;
			padding-top: $table-card-header-cell-padding;
			padding-bottom: $table-card-header-cell-padding;
			position: relative;

			/* Sorting on this heading */
			&.reactable-header-sort-desc,
			&.reactable-header-sort-asc,
			&.reactable-header-sort-always-show {
				padding-right: 13px;
			}

			&.reactable-header-sort-desc:after,
			&.reactable-header-sort-asc:after,
			&.reactable-header-sort-always-show:after {
				content: ' ';
				position: relative;
				margin-left: 4px;
				border-right: 4px solid transparent;
				border-left: 4px solid transparent;
				z-index: 1;
			}

			&.reactable-header-sort-desc:after,
			&.reactable-header-sort-always-show:after {
				border-top: 4px solid;
				border-bottom: 0px;
				top: 10px;
			}

			&.reactable-header-sort-asc:after {
				border-top: 0px;
				border-bottom: 4px solid;
				top: -10px;
			}
		}

		tr.reactable-no-data > td {
			text-align: center;
			vertical-align: middle;
			position: inherit;
		}

		td {
			padding-top: $table-card-body-cell-padding;
			padding-bottom: $table-card-body-cell-padding;

			color: $text-med;

			strong {
				font-weight: bold;
				color: $text-dark;
			}

			small {
				font-size: smaller;
				color: $text-light;
			}

			.hui-half-pill {
				margin: 8px 0;

				div, span {
					border-top-right-radius: 46px;
					border-bottom-right-radius: 46px;
					padding: 3px 12px;

					&.hui-status-running {
						background: $task-running-bg-color;
						color: white;
					}

					&.hui-status-queued {
						background: $task-queued-bg-color;
						color: $text-light;
					}

					&.hui-status-idle {
						background: $task-idle-bg-color;
						color: $text-light;
					}

					&.hui-status-unknown {
						background: $task-idle-bg-color;
						color: $text-light;
					}

					&.hui-status-error {
						background: $task-error-bg-color;
						color: white;
					}
				}
			}
		}

		th, td {
			padding-left: 7.5px;
			padding-right: 7.5px;

			&:first-child {
				padding-left: 20px;
			}
			&:last-child {
				padding-right: 20px;
			}

			&.hui-card-tablecell-align-left {
				text-align: left;
			}
			&.hui-card-tablecell-align-right {
				text-align: right;
			}
			&.hui-card-tablecell-align-center {
				text-align: center;
			}
		}

		&.hui-table-row-size-compact {
			td {
				padding-top: 4px;
				padding-bottom: 4px;
			}
		}
	}

	.hui-card-tablecard-title-section {
		margin: 15px 0 5px 0;
	}

	.hui-card-tablecard-title {
		font-size: 28px;
		color: $text-dark;
		text-align: center;
		margin: 5px 0;
	}

	.hui-card-tablecard-subtitle {
		font-size: 12px;
		color: $text-disabled;
		text-align: center;
		margin: 5px 0;
	}
}

.hui-card-badgecard {
	display: flex;
	align-items: center;
	padding: 9px 12px;
	color: $text-med;

	.hui-icon {
		vertical-align: middle;
		margin-right: 12px;

		&.hui-icon-status-good {
			@include hui-icon-status-good-xlarge;
		}

		&.hui-icon-status-warn {
			@include hui-icon-status-warn-xlarge;
		}

		&.hui-icon-status-bad {
			@include hui-icon-status-bad-xlarge;
		}
	}

	.hui-card-badgecard-main-value {
		font-size: 200%;
		font-weight: bold;
		vertical-align: -25%;
	}

	.hui-card-badgecard-details {
		margin-left: 12px;
	}
}

.hui-unentitled-card {

	&.hui-unentitled-card-centered {
		display: flex;
		flex-direction: column;
		justify-content: center;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		.hui-unentitled-card-content {
			text-align: center;
			margin: 0;
			min-height: 0;
		}
	}

	.hui-unentitled-card-content {
		line-height: 2;
		margin: 20px 20px 5px;
		font-size: 14px;
		color: $text-disabled;
		min-height: 150px;
		font-weight: 300;
	}

	.hui-unentitled-card-button {
		text-align: center;

		.hui-button {
			@include hui-button;
		}
	}
}

.hui-card-watermark {
	color: black;
	opacity: 0.1;
	position: absolute;
	margin-top: -30px;
	top: 50%;
	left: 0;
	right: 0;
	text-align: center;
	line-height: 60px;
	font: bold 60px arial;
	transform: rotate(-45deg);
}
.hui-card-categories {
	position: absolute;
	bottom: 0;
	right: 0;
	left: 0;
	height: 55px;
	display: flex;
	flex-direction: row;
	border-top: 1px solid $border-color-default;
}

.hui-card-overlay {
	background: rgba(255, 255, 255, 0.8);
	position: absolute;
	bottom: 0;
	right: 0;
	left: 0;
	top: 0;
	display: flex;
	flex-flow: column;
	text-align: center;
	justify-content: center;
}

.hui-card-category {
	flex: 1;
	display: flex;
	text-align: center;
	flex-direction: column;
	justify-content: center;
}

.hui-card-category-value {
	font-size: 18px;
	line-height: 16px;
}
.hui-card-category-label {
	font-size: 12px;
	line-height: 16px;
	color: $text-light;
}
