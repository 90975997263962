@import "../lib/colors.scss";
@import "../lib/baseComponent.scss";
@import "../lib/icons.scss";
@import "../lib/sizes.scss";

$search-padding: 8px;
$search-border: 1px;
$search-input-icon-padding: 6px;
$search-icon-size: 16px;
$search-input-padding: $search-icon-size + (2 * $search-input-icon-padding);

.hui-product-search {
	@include baseComponent;

	padding: $search-padding;
	position: relative;
	height: $row-height;
	flex-grow: 1;

	@media (max-width: $mobile-breakpoint) {
		display: block;
		vertical-align: top;
	}

	.hui-product-search-icon {
		@include hui-icon-search;
		height: $search-icon-size;
		width: $search-icon-size;
		position: absolute;
		top: 50%;
		margin-top: -0.5 * $search-icon-size;
		left: $search-border + $search-padding + $search-input-icon-padding;
	}

	&.hui-state-disabled .hui-product-search-icon {
		opacity: 0.25;
	}

	input.hui-product-search-input {
		width: 100%;
		padding-left: $search-input-padding;
		height: $row-height - ($search-padding * 2) - $search-border;
		font-size: 12px;
		border: 1px solid $border-color-default;
		border-radius: 3px;

		&:focus {
			border: 1px solid #aaa;
			outline: none;
		}
	}
}


.hui-sidebar {
	.hui-product-search {
		border-bottom: $search-border solid $border-color-default;
	}
}


.hui-dashboard-controlbar {
	.hui-product-search {
		padding-top: 0;
		padding-bottom: 0;
		margin-top: 4px; // Because of how the dropdown is spaced.
		height: 100%;
	}
}
