@import "../lib/colors.scss";
@import "../lib/icons.scss";
@import "../lib/href.scss";
@import "../lib/baseComponent.scss";
@import "../lib/sizes.scss";

$subtab-border-bottom-width: 1px;
$subtab-vertical-padding: 5px;

.hui-navbarrow { // component
	@include baseComponent;
	background: $chrome-bg-color;
	height: $row-height;
	position: relative;
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid $border-color-dark;

	.hui-navbarrow-content {
		height: $row-height;
		overflow: hidden;
	}

	.hui-navbarrow-left, .hui-navbarrow-right {
		display: flex;
	}
	.hui-navbarrow-left, .hui-navbarrow-content {
		flex-grow: 1;
	}

	.hui-navbarrow-left-controls, .hui-navbarrow-content {
		display: flex;
	}

	.hui-navbarrow-left-controls {
		flex-shrink: 0;
	}

	// this wrapper class fixes specificity issues created by its namespacing in dropdownButton.scss
	&.hui-bootstrap-container {

		.hui-navbarrow-tab-overflow.btn { // overwrite react-bootstrap
			padding: 9px 16px 10px;
			margin: 0;
			color: $text-dark;
			border-left: 1px solid $border-color-dark;
			border-radius: 0;
			height: 100%;

			& + .dropdown-menu a {
				text-decoration: none;
			}
		}

	}

	&.hui-navbarrow-subnav {
		background: white;
		height: 100%;

		@media (max-width: $mobile-breakpoint) {
			.hui-navbarrow-content {
				height: $subnav-row-height;
			}

			height: $subnav-row-height;
		}

		.hui-navbarrow-content {
			height: 100%;
			flex-wrap: wrap;
		}

		.hui-tab {
			border: none;
			$subnav-label-height: $subnav-row-height - $subtab-border-bottom-width;

			> .hui-tab-label {
				font-size: 12px;
				height: $subnav-label-height;
				line-height: $subnav-label-height - ($subtab-vertical-padding * 2);
				padding: $subtab-vertical-padding 15px;
				border: none;

				> .hui-tab-label-inner {
					padding: 0 15px;
					border: 1px solid transparent;
					white-space: nowrap;

					&:before {
						top: -2px;
					}
				}

				.hui-tab-label-inner:after {
					margin-top: -$subnav-row-height + ($subtab-vertical-padding * 2);
				}
			}

			&.hui-state-selected {

				> .hui-tab-label {
					border-bottom: 0px solid white;
					background-color: transparent;

					> .hui-tab-label-inner {
						background-color: $page-bg-color;
						border-radius: 3px;
						border: 1px solid $border-color-dark;
					}
				}

				&.hui-has-children {
					> .hui-tab-label {
						height: $subnav-label-height;
					}
				}
			}
		}

		// this wrapper class fixes specificity issues created by its namespacing in dropdownButton.scss
		&.hui-bootstrap-container {

			.hui-navbarrow-tab-overflow { // override react-bootstrap
				padding: 5px 16px;
				font-size: 14px;
			}

		}
	}
}

.hui-tab { // LI
	display: inline-block;
	list-style: none;
	border: 0 solid black;
	border-right: 1px solid $border-color-dark;

	> .hui-tab-label {
		float: left;
		padding: 0 15px;
		border-right: 1px solid white;
		font-size: 14px;
		cursor: pointer;
		position: relative;
		text-align: center;
		line-height: $row-height - $subtab-border-bottom-width - 1px;
		height: $row-height - 1px;

		.hui-tab-label-inner {
			position: relative;
			float: left;
			color: $text-dark;
			font-weight: normal;
		}

		.hui-tab-label-inner:after {
			display: block;
			content: attr(data-title);
			font-weight: bold;
			color: transparent;
			overflow: hidden;
			margin-top: -$row-height;
			background: none;
		}
	}

	&.hui-state-selected {

		> .hui-tab-label {
			text-decoration: none;
			cursor: default;
			background: white;
			border: none;

			.hui-tab-label-inner {
				font-weight: bold;
			}
		}

		&.hui-has-children {
			> .hui-tab-label {
				height: $row-height;
			}
		}
	}

	&.hui-state-disabled {

		> .hui-tab-label {
			cursor: default;

			.hui-tab-label-inner {
				color: $text-disabled;
			}
		}
	}

	&.hui-state-loading {

		.hui-tab-label-inner {

			&:before {
				@include hui-icon-loading(24px);

				content: "";
				position: absolute;
				top: 2px;
				left: 0;
				right: 0;
				width: 100%;
				height: 100%;
				opacity: .3;
			}
		}
	}

	&.hui-has-icon > .hui-tab-label {
		min-width: 44px;
		padding: 0;

		.hui-tab-icon {
			@include hui-icon-product-default;
			vertical-align: middle;
		}

		.hui-icon-sidebar-close {
			@include hui-icon-sidebar-close;
			transition-duration: .8s;
		}

		.hui-icon-sidebar-open {
			@include hui-icon-sidebar-open;
			transition-duration: .8s;
		}

		.hui-icon-current-view-community {
			@include hui-icon-current-view-community;
		}
		.hui-icon-current-view-support {
			@include hui-icon-current-view-support;
		}
	}
}

.hui-navbar-warning {
	@include baseComponent;
	color: $text-inverse;
	font-weight: 400;
	background-color: $status-orange;
	padding: 5px 12px;
}
