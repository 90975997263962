@import "../lib/colors.scss";
@import "../lib/baseComponent.scss";
@import "../lib/icons.scss";
@import "../lib/sizes.scss";

.hui-sidebar {
	@include baseComponent;
	position: relative;

	// so we don't cover the search field
	.hui-scrolling-context {
		top: $row-height;
	}

	@mixin bevel-top-bottom {
		box-shadow:
			inset 0 -1px 1px rgba(0,0,0,0.3), /*bottom internal shadow*/
			inset 0 1px 1px rgba(255,255,255,1); /*top internal highlight*/
	}

	&:before {
		content: " ";
		width: 8px;
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;
		background: linear-gradient(
			to left,
			rgba(0, 0, 0, .14),
			rgba(0, 0, 0, 0)
		);
		z-index: $base-z-index + 5;
		pointer-events: none;
	}

	.hui-sidebar-controls {
		display: flex;

		.hui-sidebar-toggle {
			@media (max-width: $mobile-breakpoint) {
				display: block;
			}

			@include hui-icon-sidebar-close;
			display: none;
			padding: $row-height / 2;
			border-left: 1px solid #bbb;
		}
	}

	.hui-sidebar-product-category {
		@include bevel-top-bottom;

		display: block;
		font-size: 12px;
		margin: 0;
		padding: 2px 14px 0 14px;
		line-height: $product-category-row-height - 2px;
		text-transform: uppercase;
		background-color: $sidebar-product-category-bg-color;
		color: $text-light;
	}

	a.hui-sidebar-product {
		display: block;
		font-size: 14px;
		padding: 0 14px;
		line-height: $product-row-height;
		border-bottom: 1px solid $border-color-default;
		cursor: pointer;
		background-color: $chrome-bg-color;
		text-decoration: none;
		color: $text-dark;

		&.hui-state-children-selected {
			background-color: $white;

			& > .hui-sidebar-product-count {
				background-color: $border-color-light;
				color: $text-disabled;
			}

		}

		&.hui-state-active {
			background-color: $barracuda-blue;
			color: $text-inverse;
			cursor: default;

			& > .hui-sidebar-product-select-icon {
				@include hui-icon-chevron-right-selected;
				height: $product-row-height;
			}

			& > .hui-sidebar-product-count {
				background-color: $medium-gray-blue;
				color: $text-inverse;
			}

			& > .hui-sidebar-product-callout {
				background-color: #bdf;
				color: #0077c8;
			}
		}

		& > .hui-sidebar-product-callout {
			display: inline-block;
			text-transform: uppercase;
			font-size: 0.75em;
			line-height: 1;
			padding: 0.2em 0.5em;
			border-radius: 1em;
			background-color: #888;
			color: #fff;
			margin: 0 0 0 1ex;
			vertical-align: 0.1em;
		}

		& > .hui-sidebar-product-select-icon {
			@include hui-icon-chevron-right;
			float: right;
			height: $product-row-height;
		}

		& > .hui-sidebar-product-count {
			float: right;
			display: inline-block;
			background-color: $border-color-default;
			color: $text-light;
			padding: 0 10px;
			margin: ($product-row-height - $product-count-height) / 2;
			line-height: $product-count-height;
			border-radius: $product-count-height;
		}

		&.hui-state-disabled {
			.hui-sidebar-product-title {
				color: $text-disabled;
			}

			&.hui-state-active {
				.hui-sidebar-product-title {
					color: $light-gray-blue;
				}
			}

			& > .hui-sidebar-product-select-icon {
				@include hui-icon-chevron-right-disabled;
				height: $product-row-height;
			}
		}

		background-position: right 34px center;
		background-repeat: no-repeat;
		background-size: 14px 14px;

		&.hui-state-busy {
			background-image: url(../images/ajax-loader.gif);
			& > .hui-sidebar-product-count {
				display: none;
			}
		}
		&.hui-state-error {
			background-image: url(../images/icn-warn.svg);

			&.hui-state-active {
				background-image: url(../images/icn-warn-selected.svg);
			}
		}
	}

	.hui-sidebar-product-icon-house {
		@include hui-icon-home;
		vertical-align: text-top;
		margin-right: 5px;
	}

	.hui-sidebar-product-content {
		background-color: $page-bg-color;
	}

	.hui-sidebar-treenode {

		.hui-sidebar-device {
			display: flex;
			flex-wrap: nowrap;
			justify-content: space-between;

			.hui-sidebar-label {
				margin-right: 20px;
				white-space: nowrap;
			}
		}

		// to support showSelectionOnly
		&.hui-sidebar-treenode-hidden,
		> .hui-sidebar-treenode-hidden {
			display: none;
		}
	}

	.hui-sidebar-treenode .hui-sidebar-treenode {
		line-height: $treenode-height;

		&.hui-state-active {
			background-color: $barracuda-blue;
			color: $text-inverse;
			cursor: default;

			.hui-sidebar-expando {
				@include hui-icon-toggle-selected;
				background-size: 6px;
				width: 14px;
				height: 14px;
			}
		}
	}

	.hui-sidebar-cache-refreshing {
		position: absolute;
		bottom: 5px;
		left: 5px;
		background-image: url(../images/ajax-loader.gif);
		background-position: left center;
		background-repeat: no-repeat;
		background-size: 25px 25px;
		height: 25px;
		width: 25px;
	}

	$treeDepthIndent: 14px;
	.hui-sidebar-treedepth-1 {
		padding-left: $treeDepthIndent;
	}
	.hui-sidebar-treedepth-2 {
		padding-left: $treeDepthIndent * 2;
	}
	.hui-sidebar-treedepth-3 {
		padding-left: $treeDepthIndent * 3;
	}
	.hui-sidebar-treedepth-4 {
		padding-left: $treeDepthIndent * 4;
	}
	.hui-sidebar-treedepth-5 {
		padding-left: $treeDepthIndent * 5;
	}

	.hui-sidebar-itemgroup-container.hui-state-active {
		.hui-sidebar-itemgroup-children .hui-sidebar-treenode {
			background-color: $light-gray-blue;
		}
	}

	.hui-sidebar-itemgroup {
		cursor: pointer;
		display: block;
		color: inherit;
		overflow: hidden;

		.hui-sidebar-expando {
			@include hui-icon-toggle;
			background-size: 6px;
			width: 14px;
			height: 14px;
			cursor: pointer;
			vertical-align: text-top;
			transform: rotate(-90deg);
			transition: transform .2s;

			&.hui-state-expanded {
				transform: rotate(0);
				transition: transform .2s;
			}
		}

		.hui-sidebar-itemgroup-title {
			display: inline-block;
			max-width: 75%;
			vertical-align: top;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		&.hui-state-active {
			& > .hui-sidebar-itemgroup-count {
				background-color: $medium-gray-blue;
				color: $text-inverse;
			}
		}

		& > .hui-sidebar-itemgroup-count {
			float: right;
			display: inline-block;
			background-color: $border-color-default;
			color: $text-light;
			padding: 0 8px;
			margin: ($treenode-height - $treenode-count-height) / 2;
			margin-right: 14px;
			line-height: $treenode-count-height;
			border-radius: $treenode-count-height;
		}
	}

	&.hui-sidebar-searching {
		.hui-sidebar-treenode,
		.hui-sidebar-treenode.hui-state-active {
			.hui-sidebar-expando {
				display: none;
			}
		}
	}

	.hui-sidebar-item-button {
		overflow: hidden;
		padding: 10px 15px;
		button {
			@include baseComponent;
			line-height: 24px;
			width: 100%;
			height: inherit;
		}
	}

	.hui-sidebar-show-all-results-toggle {
		text-align: left;
		padding: 10px;
		a {
			color: $text-light;
			font-style: italic;
			&:hover {
				color: $text-dark;
				text-decoration: underline;
			}
		}
	}

	.hui-sidebar-device {
		cursor: pointer;
		display: block;
		text-decoration: none;
		color: $text-dark;
		height: $treenode-height;

		.hui-sidebar-device-title {
			display: inline-block;
			max-width: 100%;
			padding-right: 10px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		.hui-icon-device-status {
			vertical-align: text-bottom;
			margin-right: 5px;
		}

		.hui-icon-device-status {
			@include bg-image-container(14px);
		}

		.hui-icon-device-status-good {
			@include hui-icon-status-good-small;
		}
		.hui-icon-device-status-warn {
			@include hui-icon-status-warn-small;
		}
		.hui-icon-device-status-bad {
			@include hui-icon-status-bad-small;
		}

		&.hui-state-active {

			.hui-icon-device-status-good {
				@include hui-icon-status-good-small-selected;
			}
			.hui-icon-device-status-warn {
				@include hui-icon-status-warn-small-selected;
			}
			.hui-icon-device-status-bad {
				@include hui-icon-status-bad-small-selected;
			}
		}
	}
}
