@import 'colors';

$logos: (
	// ---- PRIMARY
	// Backup
	bbs $header-bg-color-data-protection 'primary/Backup_rev_1.svg',
	// Cloud to Cloud Backup
	ccb $header-bg-color-email-protection 'primary/Cloud-to-CloudBackup_rev_2.svg',
	// Cloud to Cloud Backup for Backup
	ccbb $header-bg-color-data-protection 'primary/Cloud-to-CloudBackupforBackup_rev_1.svg',
	// Cloud App Protection
	// Cloud Control
	account $header-bg-color-app-cloud-security 'primary/CloudControl_rev_0.svg',
	support $header-bg-color-app-cloud-security 'primary/CloudControl_rev_0.svg',
	bcc $header-bg-color-app-cloud-security 'primary/CloudControl_rev_0.svg',
	// bac $header-bg-color-app-cloud-security 'primary/CloudControl_rev_0.svg',
	// Cloud Gen Access
	cga $header-bg-color-network-security 'primary/CloudGenAccess_rev_4.svg',
	// Cloud Gen Firewall
	ngf $header-bg-color-network-security 'primary/CloudGenFirewall_rev_4.svg',
	// SecureEdge
	sdwan $header-bg-color-network-security 'primary/SecureEdge_rev.svg',
	// Cloud Security Guardian
	eps $header-bg-color-app-cloud-security 'primary/CloudSecurityGuardian_rev_3.svg',
	// Data Inspector
	// Email Gateway Defense (formerly Essentials)
	ess $header-bg-color-email-protection 'primary/EP-EmailGatewayDefense_rev_2.svg',
	o365 $header-bg-color-email-protection 'primary/EP-EmailGatewayDefense_rev_2.svg',
	// Incident Response (formerly Forensics)
	fir $header-bg-color-email-protection 'primary/EP-IncidentResponse_rev_2.svg',
	// Security Awareness Training (formerly PhishLine)
	pshln $header-bg-color-email-protection 'primary/EP-SecurityAwarenessTraining_rev_2.svg',
	// Impersonation Protection (formerly Sentinel)
	sntl $header-bg-color-email-protection 'primary/EP-ImpersonationProtection_rev_2.svg',
	// WAF as a Service
	waas $header-bg-color-app-cloud-security 'primary/WAF-as-a-Service_rev_3.svg',
	// Web Application Firewall
	// ---- SECONDARY
	// Active DDoS
	// Cloud Assessment Scanner
	// Email Security Gateway
	// Email Threat Scanner
	ets $header-bg-color-email-protection 'secondary/EmailThreatScanner_rev_2.svg',
	// Firewall Control Center
	// Load Balancer
	// Message Archiver
	mas $header-bg-color-email-protection 'secondary/CloudArchivingService_rev.svg',
	// PSTEnterprise
	// Secure Access Controller
	// Secure Connector
	// Vulnerability Manager
	vlm $header-bg-color-app-cloud-security 'secondary/VulnerabilityManager_rev_3.svg',
	// Vulnerability Remediation Service
	// Web Security Gateway
);
