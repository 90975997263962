@import "../lib/colors";

/* Pagination at the bottom of our panels */
.hui-pagination-markers {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	width: 90%;
	margin: 2px auto 0 auto;
	height: 18px;
	flex-shrink: 0; // in case of flex parent

	.hui-pagination-marker {
		height: 10px;
		margin: 4px;
		cursor: pointer;
		display: inline-flex;

		&.hui-pagination-marker-type-dot {
			width: 10px;
			border-radius: 50%;
			border: 1px solid $text-light; // Yep, a text color

			&.hui-state-active {
				background-color: $text-light; // Yep, a text color
			}
		}

		&.hui-pagination-marker-type-text {
			&.hui-state-active {
				color: $text-dark; // Yep, a text color
				font-weight: bold;
			}
		}
	}

	.hui-pagination-marker-divider {
		height: 10px;
		margin: 4px;
		display: inline-flex;
		color: $medium-gray-9s;
	}
}
