@import "../lib/baseComponent";
@import "../lib/colors";
@import "../lib/buttons";
@import "../lib/icons.scss";

.hui-dialog-wrapper {
	@include baseComponent;
	line-height: 24px;
	justify-content: center;
	align-items: center;
	position: fixed;
	display: flex;
	bottom: 0;
	right: 0;
	left: 0;
	top: 0;
	z-index: 100;

	.hui-dialog-curtain {
		backdrop-filter: blur(5px);
		background: rgba(0, 0, 0, 0.4); // becomes #999 on white
		position: absolute;
		bottom: 0;
		right: 0;
		left: 0;
		top: 0;
	}

	.hui-dialog-container {
		z-index: 1;
		flex: 0 0 35%;
		background: #fff;
		padding: 10px;
		box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
		border-radius: 3px;
	}
}

.hui-dialog-closebox {
	float: right;
	padding: 10px 10px 0 10px;
	margin-top: -4px; // to line up with the title

	.hui-dialog-closebox-icon {
		@include hui-icon-close;
	}

	&:hover {
		.hui-dialog-closebox-icon {
			@include hui-icon-close-hover;
		}
	}
}

.hui-dialog-title {
	font-size: 18px;
	padding: 5px 10px;
	color: #555555;
	font-weight: bold;
}

.hui-dialog-content {
	padding: 5px 10px;
	color: #333333;
	min-width: 300px;
}

.hui-dialog-buttons {
	padding: 10px;
	overflow: hidden;
}

.hui-dialogbutton.btn-sm.btn {
	padding: 5px 10px;
	background-color: white;
	background-image: linear-gradient(to bottom, white, #ebebeb);
	border: 1px solid #ddd;
	color: #666;
	margin: 0 0 0 10px;
	font-weight: normal;
	float: right;
	min-width: 60px;
	text-align: center;
	border-radius: 3px;

	&.hui-dialogbutton-isDefault.btn-default {
		background-color: $button-light-blue;
		background-image: linear-gradient(to bottom, $button-gradient-top-color, $button-gradient-bottom-color);
		color: white;
	}

	&.hui-dialogbutton-isCancel {
		margin: 0 10px 0 0;
		float: left;
	}
}
