@import "../lib/colors.scss";

@mixin hui-button {
	background-color: $button-light-blue;
	background-image: linear-gradient(to bottom, $button-gradient-top-color, $button-gradient-bottom-color);
	color: $white;
	padding: 3px 10px;
	font-size: 12px;
	border-radius: 3px;
	border: 0;
	font-family: Lato, helvetica, arial, sans-serif;
	font-weight: bold;

	&:hover, &:active, &:focus {
		color: $white;
	}

	&:active, &:focus {
		background-image: none;
		background-color: $button-pressed-color;
	}
}
