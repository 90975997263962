//================================
// Color Palette
//================================

// Try not to use these directly, prefer application color variables

$off-black-3s: #333; // 51, 51, 51
$off-black-4s: #444; // 68, 68, 68
$dark-gray-5s: #555; //85, 85, 85
$dark-gray-7s: #777; //119, 119, 119
$medium-gray-9s: #999; // 153, 153, 153
$medium-gray-A8: #a8a8a8; // 168, 168, 168
$medium-gray-Bs: #bbb; // 187, 187, 187
$light-gray-Ds: #ddd; // 221, 221, 221
$light-gray-Es: #eee; // 238, 238, 238
$off-white: #f7f7f7; // 247, 247, 247
$white: white;

$status-green: #00a651;
$status-red: #ff0000;
$status-yellow: #ddbd1e;
$barracuda-blue: #0077c8; // 0, 119, 200
$medium-gray-blue: #4d90ce;
$light-gray-blue: mix($barracuda-blue, $off-white, 20%);
$link-blue: #0654BA;

//================================
// Global Application Colors
//================================

// Backgrounds
$header-bg-color: $off-black-4s;
$chrome-bg-color: $light-gray-Es;
$page-bg-color: $off-white;
$error-bg-color: $white;
$task-running-bg-color: $medium-gray-blue;
$task-queued-bg-color: $light-gray-Ds;
$task-idle-bg-color: $light-gray-Es;

// Borders
$border-color-default: $light-gray-Ds;
$border-color-light: $light-gray-Es;
$border-color-dark: $medium-gray-Bs;

// Text
$text-inverse: $white;
$text-disabled: $medium-gray-9s;
$text-light: $dark-gray-7s;
$text-med: $dark-gray-5s;
$text-dark: $off-black-3s;
$text-link: $barracuda-blue;
$text-success: $status-green;
$text-error: $status-red;


// Provided by design, but not currently implemented
	$secondary-information: $dark-gray-7s; // "use sparingly" per designer
	$shadow-color: $medium-gray-Bs;
	// Status Colors
	$status-orange: #f18e00;
	$status-info: $text-light;

$header-bg-color-email-protection: rgba(23, 38, 25, 1);
$header-bg-color-app-cloud-security: rgba(0, 45, 59, 1);
$header-bg-color-network-security: rgba(22, 21, 33, 1);
$header-bg-color-data-protection: rgba(40, 22, 13, 1);

//================================
// One-offs
//================================

$search-highlight-bg-color: #f6e730;
$button-dark-blue: #0061A4;
$button-light-blue: $barracuda-blue;
$sidebar-product-category-bg-color: $light-gray-Ds;
$task-running-bg-color: $medium-gray-blue;
$task-queued-bg-color: $light-gray-Ds;
$task-idle-bg-color: $light-gray-Es;
$task-error-bg-color: #DB1F31;
$button-pressed-color: $button-dark-blue;
$button-gradient-top-color: $button-light-blue;
$button-gradient-bottom-color: $button-dark-blue;
$table-highlighted-bg-color: #EBF5FC;
$table-highlighted-bg-color-odd: #E7F1F8;
$label-warning: #ee9800;
$label-danger: #dd1e31;

.hui-page-body {
	background-color: $page-bg-color;
}
